/*
| ===================================================
| This File
| ===================================================
*/
<template>
  <v-layout wrap justify-start fill-height>
    <v-flex xs12 text-center align-self-center>
      <h1>Page not found!</h1>
      <v-layout wrap justify-start>
        <v-flex xs12 pt-2>
          <v-btn color="primary" dark :ripple="false" depressed :to="{name:'home'}">
            <span class="text-capitalize">Go Back</span>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  name: "error"
}
</script>

<style scoped>

</style>